import React from "react"
import styles from "./styles.module.scss"

const WorkPosition = props => {
  const imageUrl = props.image && props.image.fields.file.url

  return (
    <div className={styles.container}>
      <img src={`https:${imageUrl}?q=70`} alt="" className={styles.image} />
      <div className={styles.info}>
        <h3>{props.title}</h3>
        <p className={styles.description}>{props.description}</p>
        <a href={props.link}>{props.linkTitle}</a>
      </div>
    </div>
  )
}

export default WorkPosition
