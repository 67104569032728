import { useState, useEffect } from "react"
import { createClient } from "contentful"
import { normalizeContentful } from "utils/normalizeContentful"

const CLIENT = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

export const useApi = defaultData => {
  const [data, setData] = useState(defaultData || {})

  useEffect(() => {
    CLIENT.getEntries({ content_type: "section" })
      .then(entries => {
        const sections = normalizeContentful(
          entries.items.map(entry => entry.fields)
        )
        setData(sections)
      })
      .catch(console.error)
  }, [])
  return { data }
}
