import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"
import Button from "components/Button"

const DownloadPDF = props => {
  const { products } = useContext(GlobalContext)

  const pdfs = normalizeUrlsFromImageList(
    products.leverans_deklarationer.imageList
  )
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Produkter</h2>
      <p className={styles.desc}>
        Dalahus tillverkas och marknadsförs av Huskomponenter Linghed AB.
        Dalahus består av monteringsfärdiga materielleveranser till
        enfamiljshus, fritidshus, gruppbostäder och flerfamiljshus. Då vi gärna
        arbetar med kundanpassade lösningar så offererar vi på kundspecifika
        ritningar som ska läsas med någon av våra leveransdeklarationer.
        Leveransdeklartionerna använder vi som utgångspunkt för
        materielleveransen. Det är naturligtvis möjligt för kunden att göra
        ändringar och individuella val.
      </p>

      <p className={styles.punchLine}>
        Läs våra leveransdeklartioner för enfamiljshus här:{" "}
      </p>

      <div className={styles.pdfContainer}>
        {Object.values(pdfs).map(pdf => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            key={pdf.title}
            href={pdf.url}
            download
            className={styles.pdfLink}
          >
            <Button className={styles.button} variant="primary-ghosted">
              {pdf.title}
            </Button>
          </a>
        ))}
      </div>
      <div className={styles.divider} />
    </div>
  )
}

export default DownloadPDF
