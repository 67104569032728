import React from "react"
import styles from "./styles.module.scss"

const CopyrightFooter = props => {
  return (
    <div className={styles.container}>
      <span className={styles.copyright}>{"\u00A9"}</span>
      <span className={styles.text}>{props.text}</span>
    </div>
  )
}

export default CopyrightFooter
