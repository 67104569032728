import React from "react"
import styles from "./styles.module.scss"
import EmployeeCard from "components/EmployeeCard"

const Employees = props => {
  return (
    <div className={styles.container}>
      <EmployeeCard employee={props.employee} />
    </div>
  )
}

export default Employees
