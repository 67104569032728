import React from "react"
import { Link } from "@reach/router"
import styles from "./styles.module.scss"
import cx from "classnames"

const Logo = props => {
  return (
    <Link
      className={cx(styles.link, props.className)}
      to="/"
      title={`Gå till startsidan`}
    >
      <div className={styles.logo} />
      <p className={styles.slogan}>
        <i>Unika byggen, inga kopior</i>
      </p>
    </Link>
  )
}

export default Logo
