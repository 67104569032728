import React from "react"
import styles from "./styles.module.scss"
import cx from "classnames"

const DropdownMenu = props => {
  return (
    <div className={cx(styles.container, props.className)}>
      {props.children}
    </div>
  )
}

export default DropdownMenu
