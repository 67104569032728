import React, { useState } from "react"
import Image from "./Image"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"
import cx from "classnames"
import Lightbox from "react-images"
import { normalizeImageToLightbox } from "utils/normalizeUrl"
import lightboxTheme from "./lightboxTheme"

const ImageSlider = props => {
  const { images } = props

  const [currentImageIndex, setCurrentImageIndex] = useState(
    images.length === 1 ? 0 : 1
  )

  const handleNextClick = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length)
  }

  const handlePrevClick = () => {
    let nextImageIndex = currentImageIndex - 1

    if (nextImageIndex < 0) {
      nextImageIndex = images.length - 1
    }

    setCurrentImageIndex(nextImageIndex)
  }

  const getImageClassName = index => {
    const lastIndexOfImages = images.length - 1

    if (index === currentImageIndex || lastIndexOfImages === 0)
      return "currentImage"
    else if (
      index === currentImageIndex - 1 ||
      (currentImageIndex === 0 &&
        index === lastIndexOfImages &&
        lastIndexOfImages !== 0)
    )
      return "prevImage"
    else if (
      index === currentImageIndex + 1 ||
      (currentImageIndex === lastIndexOfImages &&
        index === 0 &&
        lastIndexOfImages !== 0)
    )
      return "nextImage"
  }

  const lightboxImages = normalizeImageToLightbox(images)
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)

  const [currentLightboxImageIndex, setCurrentLightboxImageIndex] = useState(
    lightboxImages.length === 1 ? 0 : 0
  )

  const clickImage = currentLightboxImageIndex => {
    setCurrentLightboxImageIndex(currentLightboxImageIndex)
    setLightboxIsOpen(true)
  }
  const close = () => {
    setCurrentLightboxImageIndex(0)
    setLightboxIsOpen(false)
  }

  const handleLightboxNext = () =>
    setCurrentLightboxImageIndex(currentLightboxImageIndex + 1)

  const handleLightboxPrev = () =>
    setCurrentLightboxImageIndex(currentLightboxImageIndex - 1)

  const handleThumbnailClick = index => setCurrentLightboxImageIndex(index)

  return (
    <div className={styles.container}>
      <Lightbox
        images={lightboxImages}
        currentImage={currentLightboxImageIndex}
        onClickPrev={handleLightboxPrev}
        onClickNext={handleLightboxNext}
        onClickThumbnail={handleThumbnailClick}
        isOpen={lightboxIsOpen}
        onClose={close}
        onClickImage={close}
        imageCountSeparator=" av "
        showThumbnails
        theme={lightboxTheme}
        width={1280}
      />
      <div className={styles.imageWrapper}>
        {images.length > 1 && (
          <div
            className={cx(styles.icon, styles.iconPrev)}
            onClick={handlePrevClick}
          >
            <FaIcon icon="arrow-left" prefix="fal" />
          </div>
        )}

        {images.map((image, index) => {
          return (
            <Image
              index={currentImageIndex}
              onClick={clickImage}
              key={image.sys.id}
              image={image}
              status={getImageClassName(index)}
            />
          )
        })}

        {images.length > 1 && (
          <div
            className={cx(styles.icon, styles.iconNext)}
            onClick={handleNextClick}
          >
            <FaIcon icon="arrow-right" prefix="fal" />
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageSlider
