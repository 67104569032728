import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import EmployeeCard from "components/EmployeeCard"

const Employees = props => {
  const { jrdalabygg } = useContext(GlobalContext)

  return (
    <div className={styles.container}>
      <EmployeeCard employee={jrdalabygg.jesper_malmqvist} />
    </div>
  )
}

export default Employees
