import React from "react"
import styles from "./styles.module.scss"
import cx from "classnames"

const Button = props => {
  return (
    <button
      className={cx(styles.container, props.className, styles[props.variant])}
    >
      {props.children}
    </button>
  )
}

export default Button
