import React from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"

const Step = props => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <FaIcon icon={props.icon} prefix="fal" className={styles.icon} />
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{props.title || "Titel saknas"}</h3>
        <p className={styles.description}>
          {props.description || "Beskrivning saknas"}
        </p>
      </div>
    </div>
  )
}

export default Step
