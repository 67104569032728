import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import Milestone from "./Milestone"

const Timeline = props => {
  const { aboutcompany, timeline } = useContext(GlobalContext)

  return (
    <section className={styles.container}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{aboutcompany.timeline.title}</h2>
        <p className={styles.description}>
          {aboutcompany.timeline.description}
        </p>
      </div>
      <div className={styles.timelineContainer}>
        {Object.values(timeline).map((item, index) => (
          <Milestone
            key={item.key}
            {...item}
            className={styles.popupItem}
            index={index}
            timelineLength={Object.keys(timeline).length}
          />
        ))}
      </div>
    </section>
  )
}

export default Timeline
