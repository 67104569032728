import React from "react"
import styles from "./styles.module.scss"
import cx from "classnames"

const ImageWithText = props => {
  return (
    <div className={styles.container}>
      <img
        src={`${props.imageUrl}?q=70`}
        className={cx(styles.image, props.className)}
        alt={props.text}
      />
      <span className={styles.imageText}>{props.text}</span>
    </div>
  )
}

export default ImageWithText
