import React, { useContext } from "react"
import MainLayout from "layout/MainLayout"
import { GlobalContext } from "globalContext"
import Hero from "./Hero"
import ImageSlider from "components/ImageSlider"
import styles from "./styles.module.scss"
import DescriptionSection from "./DescriptionSection"
import slugify from "utils/slugify"
import ContactConsumer from "components/ContactConsumer"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"

const ProjectShow = props => {
  return (
    <MainLayout>
      <ProjectContainer id={props.id} />
    </MainLayout>
  )
}

const ProjectContainer = props => {
  const { projects } = useContext(GlobalContext)

  const project = Object.values(projects).find(
    project => project.key === props.id || slugify(project.name) === props.id
  )

  ReactGA.pageview(`/objekt/${project.key}`)

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Dalahus - ${project.name} | Unika byggen, inga kopior.`}</title>

        <meta name="twitter:card" content="summary" />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta
          name="twitter:title"
          content={`Dalahus - ${project.name} | Unika byggen, inga kopior.`}
        />

        <meta
          name="twitter:description"
          content={project.description && project.description}
        />

        <meta
          name="twitter:image"
          content={
            project.primaryImage &&
            `https:${project.primaryImage.fields.file.url}?q=70`
          }
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content={project.description && project.description}
        />

        <meta
          property="og:title"
          content={`Dalahus - ${project.name} | Unika byggen, inga kopior.`}
        />

        <meta
          property="og:description"
          content={project.description && project.description}
        />

        <meta
          property="og:image"
          content={
            project.primaryImage
              ? `https:${project.primaryImage.fields.file.url}?q=70`
              : "https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
          }
        />

        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />

        <meta
          property="og:url"
          content={`https://dalahus.se/objekt/${project.key}`}
        />
      </Helmet>
      <Hero project={project} />
      <DescriptionSection project={project} />

      {project.images && (
        <section className={styles.imageSliderContainer}>
          <ImageSlider images={project.images} />
        </section>
      )}
      <ContactConsumer />
    </React.Fragment>
  )
}

export default ProjectShow
