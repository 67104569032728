import React from "react"
import MainLayout from "layout/MainLayout"
import Hero from "./Hero"
import Description from "./Description"
import Employees from "./Employees"
import Instagram from "./Instagram"
import { Helmet } from "react-helmet"

const JRDalaBygg = props => {
  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - JR Dala Bygg | Unika byggen, inga kopior.</title>

        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus - JR Dala Bygg | Unika byggen, inga kopior."
        />

        <meta
          name="twitter:description"
          content="JR Dala Bygg AB startades 2014 och är delvis ett byggserviceföretag och i huvudsak montagebolag till Dalahus."
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/jr-dala-bygg-thumb.png"
        />

        <meta http-equiv="Expires" content="1" />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="JR Dala Bygg AB startades 2014 och är delvis ett byggserviceföretag och i huvudsak montagebolag till Dalahus. "
        />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta
          property="og:title"
          content="Dalahus - JR Dala Bygg | Unika byggen, inga kopior"
        />

        <meta
          property="og:description"
          content="JR Dala Bygg AB startades 2014 och är delvis ett byggserviceföretag och i huvudsak montagebolag till Dalahus. "
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/jr-dala-bygg-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/jr-dala-bygg" />
      </Helmet>
      <Hero />
      <Instagram />
      <Description />
      <Employees />
    </MainLayout>
  )
}

export default JRDalaBygg
