import React from "react"
import Tabs from "components/Tabs"
import styles from "./styles.module.scss"

const Phase = props => (
  <article className={styles.container}>
    <section className={styles.infoContainer}>
      <h2>{props.title}</h2>
      <Tabs tabs={props.tabs} />
    </section>

    <div className={styles.indicator}>
      <div className={styles.indicatorArm} />
    </div>

    <div className={styles.imageContainer}>
      <img
        alt=""
        className={styles.image}
        src={`https:${props.imageUrl}?q=70`}
      />
    </div>
  </article>
)

export default Phase
