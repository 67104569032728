import React, { useContext } from "react"
import MainLayout from "layout/MainLayout"
import { GlobalContext } from "globalContext"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"
import styles from "./styles.module.scss"
import WorkPosition from "./WorkPosition"

const Work = () => {
  const { work, workpositions } = useContext(GlobalContext)

  if (!work) return null

  const filterPositions = values => {
    if (values)
      return Object.values(values).filter(
        value => value.key !== "Ribbelement-product"
      )
  }

  ReactGA.pageview("/jobb")

  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - Jobba hos oss</title>

        <meta name="twitter:card" content="summary" />

        <meta http-equiv="Expires" content="1" />

        <meta name="twitter:title" content="Dalahus - Jobba hos oss" />

        <meta
          name="keywords"
          content="Dalahus,Massivpress,Ribbelement,KL-Trä,CLT,Falun,Linghed,Jobb"
        />

        <meta name="twitter:description" content="Dalahus - Jobba hos oss" />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Dalahus är ett företag som bedriver industriellt byggande i trä. Vår träfabrik är beläget i Linghed, ca 35 km nordost om Falun. I Linghed startades produktionen av prefabricerade träkomponenter redan 1938."
        />

        <meta property="og:title" content="Dalahus - Jobba hos oss" />

        <meta
          property="og:description"
          content="Dalahus är ett företag som bedriver industriellt byggande i trä. Vår träfabrik är beläget i Linghed, ca 35 km nordost om Falun. I Linghed startades produktionen av prefabricerade träkomponenter redan 1938."
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/ribbelement" />
      </Helmet>
      <div className={styles.filler}></div>
      <div className={styles.container}>
        <h1 className={styles.title}>{work.title.value}</h1>
        <p>{work.description.largeValue}</p>
        <h2 className={styles.subtitle}>Annonserade tjänster</h2>
        <p className={styles.bread}>
          Just nu har vi inga annonserade tjänster men du är välkommen att
          skicka in ditt CV/Ansökan enligt länkarna nedan.
        </p>
        {filterPositions(workpositions) &&
          filterPositions(workpositions).map(product => (
            <WorkPosition
              description={product.jobdescription}
              title={product.jobtitle}
              image={product.jobimage}
              key={product.key}
              link={product.workbusterlink}
              linkTitle={product.linkTitle}
            />
          ))}
      </div>
    </MainLayout>
  )
}

export default Work
