import React from "react"
import MainLayout from "layout/MainLayout"
import GDPRSection from "./GDPRSection"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"

const GDPR = () => {
  ReactGA.pageview("/gdpr")
  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - GDPR | Unika byggen, inga kopior.</title>

        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus - GDPR | Unika byggen, inga kopior"
        />

        <meta
          name="twitter:description"
          content="Hur hanterar vi på Dalahus dina personuppgifter? För oss är det viktigt att du som kund känner dig trygg i hur vi hanterar dina personuppgifter"
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Hur hanterar vi på Dalahus dina personuppgifter? För oss är det viktigt att du som kund känner dig trygg i hur vi hanterar dina personuppgifter"
        />

        <meta
          property="og:title"
          content="Dalahus - GDPR | Unika byggen, inga kopior"
        />

        <meta
          property="og:description"
          content="Hur hanterar vi på Dalahus dina personuppgifter? För oss är det viktigt att du som kund känner dig trygg i hur vi hanterar dina personuppgifter"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/gdpr" />
      </Helmet>
      <GDPRSection />
    </MainLayout>
  )
}

export default GDPR
