import React, { useState } from "react"
import NavLink from "./NavLink"
import FakeLink from "./FakeLink"
import styles from "./styles.module.scss"
import DropdownMenu from "./DropdownMenu"
import cx from "classnames"
import { filterLinks } from "utils/filterLinks"

const Navbar = ({ links, className }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const navLinks = filterLinks(links, "navbar")
  const dropDownLinks = filterLinks(links, "dropdown")

  let timer = undefined

  const openMenu = () => {
    if (timer) {
      clearTimeout(timer)
    }
    setMenuIsOpen(true)
  }
  const closeMenu = () => {
    timer = setTimeout(() => setMenuIsOpen(false), 1500)
  }

  return (
    <nav className={cx(styles.container, className)}>
      <ul>
        {navLinks.map(link => (
          <li key={link.value} className={styles.listItem}>
            <NavLink className={styles.link} to={link.url} value={link.value}>
              {link.value}
            </NavLink>
          </li>
        ))}

        <li
          onMouseEnter={openMenu}
          onMouseLeave={closeMenu}
          className={styles.about}
        >
          <FakeLink className={styles.link}>{links.about_us.value}</FakeLink>
          <DropdownMenu
            className={cx(styles.dropdownMenu, { [styles.isOpen]: menuIsOpen })}
          >
            {dropDownLinks.map(link => (
              <NavLink
                key={link.value}
                className={styles.dropdownLink}
                to={link.url}
                value={link.value}
              >
                {link.value}
              </NavLink>
            ))}
          </DropdownMenu>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
