export const normalizeContentful = array => {
  if (array) {
    return array.reduce(
      (acc, section) => ({
        ...acc,
        [section.name.toLowerCase().replace(" ", "")]:
          section.children &&
          section.children.reduce(
            (acc, fieldObj) => ({
              ...acc,
              [fieldObj.fields.key]: fieldObj.fields,
            }),
            {}
          ),
      }),
      {}
    )
  }
}
