import React from "react"
import styles from "./styles.module.scss"
import Button from "components/Button"
import { Link } from "@reach/router"

const History = props => {
  const { texts } = props

  return (
    <div className={styles.container}>
      <p className={styles.subTitle}>{texts.history.subtitle}</p>
      <h2 className={styles.title}>{texts.history.title}</h2>
      <p className={styles.description}>{texts.history.description}</p>
      <div className={styles.buttonContainer}>
        <Link to="/om-foretaget" title={`Läs mer om företaget`}>
          <Button variant="primary-ghosted" className={styles.button}>
            {texts.history.primaryButton}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default History
