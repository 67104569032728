import React from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"
import { Link } from "@reach/router"
import cx from "classnames"

const ProjectCard = props => {
  const { categories, name, year, image, className, urlKey } = props

  const printCategories = categories => {
    if (categories)
      return categories.map(category => (
        <span key={category}>{`${category} `}</span>
      ))
  }

  return (
    <Link
      to={`/objekt/${urlKey}`}
      className={cx(styles.container, className)}
      title={`Besök ${name}`}
    >
      <img src={`https:${image}?q=70`} alt={name} className={styles.image} />
      <div className={styles.overlay} />
      <div className={styles.bottomSection}>
        <div className={styles.left}>
          <span className={styles.name}>{name}</span>
          <span className={styles.year}>
            {year ? year : printCategories(categories)}
          </span>
        </div>
        <div className={styles.right}>
          <span className={styles.button}>
            <FaIcon icon="arrow-right" prefix="fal" className={styles.icon} />
          </span>
        </div>
      </div>
    </Link>
  )
}

export default ProjectCard
