import React, { useContext } from "react"
import styles from "./styles.module.scss"
import Steps from "./Steps"
import { GlobalContext } from "globalContext"
import Button from "components/Button"
import { Link } from "@reach/router"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"

const Process = props => {
  const { startpage } = useContext(GlobalContext)

  if (!startpage) return null

  const image = normalizeUrlsFromImageList(startpage.process_images.imageList)

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{startpage.process.title}</h2>
      <img
        src={`${image.arbete.url}?q=70`}
        alt="temp"
        className={styles.image}
      />
      <Steps />
    </section>
  )
}

export default Process
