import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"

const Introduction = () => {
  const { newstartpage } = useContext(GlobalContext)

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{newstartpage.introduction.title}</h2>
      <p className={styles.description}>
        {newstartpage.introduction.description}
      </p>
    </section>
  )
}

export default Introduction
