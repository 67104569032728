import React from "react"
import styles from "./styles.module.scss"

const Section = props => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>{props.title}</p>
        {props.children}
      </div>
    </section>
  )
}

export default Section
