import React, { useContext } from "react"
import Section from "components/Section"
import ContactSection from "components/ContactSection"
import HistorySection from "components/HistorySection"
import { GlobalContext } from "globalContext"

const ContactConsumer = () => {
  const { startpage } = useContext(GlobalContext)

  return (
    <Section>
      <HistorySection texts={startpage} />
      <ContactSection texts={startpage} />
    </Section>
  )
}

export default ContactConsumer
