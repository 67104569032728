import React from "react"
import styles from "./styles.module.scss"

const Hero = props => {
  const { project } = props

  return (
    <section
      className={styles.hero}
      style={{
        backgroundImage: `url(https:${
          project.primaryImage.fields.file.url
        }?q=70)`,
      }}
    >
      <div className={styles.overlay} />

      <div className={styles.heroContent}>
        <h1 className={styles.title}>{project.name}</h1>
        <div className={styles.projectInfo}>
          {project.bya && (
            <div className={styles.infoSection}>
              <p className={styles.label}>BYA:</p>
              <p className={styles.text}>{project.bya} m²</p>
            </div>
          )}

          {project.bra && (
            <div className={styles.infoSection}>
              <p className={styles.label}>BRA:</p>
              <p className={styles.text}>{project.bra} m²</p>
            </div>
          )}

          {project.buildYear && (
            <div className={styles.infoSection}>
              <p className={styles.label}>Byggår:</p>
              <p className={styles.text}>{project.buildYear}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
