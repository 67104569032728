import React from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"
import Button from "components/Button"

const Instagram = props => {
  return (
    <div className={styles.container}>
      <div className={styles.instaContainer}>
        <FaIcon icon="instagram" prefix="fab" className={styles.icon} />
        <span>Följ JR Dala Bygg på Instagram för att följa vårt arbete</span>
        <a
          href="https://www.instagram.com/jrdalabyggab/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="primary-ghosted">Följ oss på Instagram</Button>
        </a>
      </div>
    </div>
  )
}

export default Instagram
