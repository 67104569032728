import React from "react"
import MainLayout from "layout/MainLayout"
import Hero from "./Hero"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"
import Inspiration from "./Inspiration"
import Process from "./Process"
import ContactConsumer from "components/ContactConsumer"

const ForPrivate = () => {
  ReactGA.pageview("/privat")

  return (
    <MainLayout>
      <Helmet>
        <title>
          Dalahus | Unika byggen, inga kopior. Vi hjälper dig att bygga hus
        </title>

        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus | Unika byggen, inga kopior. Vi hjälper dig att bygga hus"
        />

        <meta
          name="twitter:description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          property="og:title"
          content="Dalahus | Unika byggen, inga kopior. Vi hjälper dig att bygga hus"
        />

        <meta
          property="og:description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se" />
      </Helmet>
      <Hero />
      <Inspiration />
      <Process />
      <ContactConsumer />
    </MainLayout>
  )
}

export default ForPrivate
