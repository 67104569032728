import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"

const Hero = () => {
  const { contactpage: contact } = useContext(GlobalContext)

  const heroImage = normalizeUrlsFromImageList(
    contact.contacts_hero_image.imageList
  )

  return (
    <section className={styles.hero}>
      <div className={styles.overlay} />
      <div className={styles.skew} />

      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{contact.hero_title.value}</h1>
        <p className={styles.description}>{contact.hero_description.value}</p>

        <div className={styles.contactWrapper}>
          <a
            href={`mailto:${contact.hero_email.value}`}
            className={styles.email}
            title={`Maila oss`}
          >
            {contact.hero_email.value}
          </a>
          <a
            href={`tel:+46${contact.hero_phone.value.replace(/^0+/, "")}`}
            className={styles.phone}
            title={`Ring oss`}
          >
            {contact.hero_phone.value}
          </a>
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${heroImage.hero_image.url}?q=70)` }}
        className={styles.heroImage}
      />
    </section>
  )
}

export default Hero
