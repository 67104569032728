import React from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"

const EmployeeCard = props => {
  const { employee } = props

  return (
    <section className={styles.employeeCard}>
      <div
        className={styles.avatar}
        style={{
          backgroundImage:
            employee.image &&
            `url(https:${employee.image.fields.file.url}?w=200&h=200)`,
        }}
      >
        {!employee.image && (
          <FaIcon icon="user" prefix="fal" className={styles.icon} />
        )}
      </div>

      <span className={styles.name}>{employee.name}</span>
      <span className={styles.cardItem}>{employee.role}</span>

      <a
        className={styles.cardItem}
        href={`tel:+46${employee.phone.replace(/^0+/, "")}`}
        title={`Ring mig`}
      >
        {employee.phone}
      </a>
      <a
        className={styles.cardItem}
        href={`mailto:${employee.email}`}
        title={`Maila mig`}
      >
        {employee.email}
      </a>
    </section>
  )
}

export default EmployeeCard
