import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import ReactGA from "react-ga"

const Description = props => {
  const { jrdalabygg } = useContext(GlobalContext)

  ReactGA.pageview("/jr-dala-bygg")
  return (
    <div className={styles.container}>
      <p className={styles.text}>{jrdalabygg.company_story.value}</p>
      <p className={styles.text}>{jrdalabygg.company_info.largeValue}</p>
    </div>
  )
}

export default Description
