import React from "react"
import styles from "./styles.module.scss"
import Introduction from "./Introduction"
import SellingPoints from "./SellingPoints"

const Presentation = () => {
  return (
    <section className={styles.container}>
      <Introduction />
      <SellingPoints />
    </section>
  )
}

export default Presentation
