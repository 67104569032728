import React from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"

const FakeLink = props => {
  return (
    <div className={styles.container}>
      {props.children}
      <FaIcon icon="chevron-down" prefix="fal" className={styles.icon} />
    </div>
  )
}

export default FakeLink
