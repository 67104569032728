import React, { useContext } from "react"
import styles from "./styles.module.scss"
import cx from "classnames"
import Header from "components/Header"
import Footer from "components/Footer"
import { GlobalContext } from "globalContext"

const MainLayout = props => {
  const { newlinks, footer } = useContext(GlobalContext)

  if (!newlinks) return null

  return (
    <div className={styles.container}>
      <Header links={newlinks} />
      <section className={cx(styles.content, props.className)}>
        {props.children}
      </section>
      <Footer texts={footer} links={newlinks} />
    </div>
  )
}

export default React.memo(MainLayout)
