import React from "react"
import styles from "./styles.module.scss"
import cx from "classnames"
import { CSSTransition, TransitionGroup } from "react-transition-group"

const Tabs = ({ tabs }) => {
  const tabsList = Object.values(tabs)

  const [activeTabId, setActiveTabId] = React.useState(
    tabsList[0] ? tabsList[0].id : undefined
  )

  return (
    <div className={styles.container}>
      <nav>
        {tabsList.map(tab => {
          return (
            <Tab
              key={tab.id}
              label={tab.label}
              onClick={() => setActiveTabId(tab.id)}
              active={activeTabId === tab.id}
            />
          )
        })}
      </nav>

      <TransitionGroup>
        <CSSTransition key={activeTabId} timeout={350} classNames="fade-up">
          <div>
            {tabs[activeTabId].renderContent.map(content => (
              <p key={content} className={styles.content}>
                {content}
              </p>
            ))}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

const Tab = ({ onClick, label, active }) => (
  <button
    className={cx(styles.tab, { [styles.activeTab]: active })}
    onClick={onClick}
  >
    {label}
  </button>
)

export default Tabs
