import React from "react"
import styles from "./styles.module.scss"
import backgroundImage from "./background.png"

const Hero = () => (
  <section
    className={styles.container}
    style={{ backgroundImage: `url(${backgroundImage}?q=70)` }}
  >
    <h1>Byggprocessen</h1>
  </section>
)

export default Hero
