import React, { useContext } from "react"
import styles from "./styles.module.scss"
import FeaturedProjects from "./FeaturedProjects"
import { GlobalContext } from "globalContext"

const Inspiration = props => {
  const { startpage } = useContext(GlobalContext)

  if (!startpage) return null

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{startpage.inspiration.title}</h2>
      <p className={styles.description}>{startpage.inspiration.description}</p>
      <FeaturedProjects />
    </section>
  )
}

export default Inspiration
