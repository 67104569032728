import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"
import ImageWithText from "components/ImageWithText"
import cx from "classnames"

const About = props => {
  const { aboutcompany } = useContext(GlobalContext)

  const images = normalizeUrlsFromImageList(aboutcompany.images.imageList)

  return (
    <section className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{aboutcompany.about.title}</h2>
          <p className={styles.description}>{aboutcompany.about.description}</p>
          <p className={styles.description}>
            Antal anställda: {aboutcompany.number_of_employees.value}
          </p>
        </div>
        <ImageWithText
          className={styles.image}
          imageUrl={images.husleveranser1946.url}
          text={images.husleveranser1946.title}
        />
      </div>
      <div className={styles.divider} />
      <section className={styles.bottomRow}>
        <ImageWithText
          className={cx(styles.image, styles.leftImage)}
          imageUrl={images.tillverkning.url}
          text={images.tillverkning.title}
        />
        <ImageWithText
          className={cx(styles.image, styles.rightImage)}
          imageUrl={images.vägglinjen.url}
          text={images.vägglinjen.title}
        />
      </section>
    </section>
  )
}

export default About
