import React from "react"
import { Link } from "@reach/router"
import styles from "./styles.module.scss"
import cx from "classnames"

const NavLink = props => (
  <Link
    title={`Gå till ${props.value}`}
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: cx(styles.link, props.className, {
          [styles.current]: isCurrent,
        }),
      }
    }}
  />
)
export default NavLink
