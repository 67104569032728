import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import BackgroundSlideshow from "./BackgroundSlideshow"

const Hero = props => {
  const { forprivate } = useContext(GlobalContext)

  return (
    <section className={styles.container}>
      <BackgroundSlideshow images={forprivate.slider_images.imageList} />

      <div className={styles.image} alt="large house">
        <div className={styles.overlay} />
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{forprivate.hero.title}</h1>
          <p className={styles.description}>{forprivate.hero.description}</p>
        </div>
      </div>
    </section>
  )
}

export default Hero
