import React, { useContext } from "react"
import styles from "./styles.module.scss"
import Button from "components/Button"
import { Link } from "@reach/router"
import FaIcon from "components/FaIcon"
import { GlobalContext } from "globalContext"
import BackgroundSlideshow from "./BackgroundSlideshow"

const Hero = props => {
  const { startpage } = useContext(GlobalContext)

  const handleClick = e => {
    window.scrollTo({
      top: 1000,
      behavior: "smooth",
    })
  }

  return (
    <section className={styles.container}>
      <BackgroundSlideshow images={startpage.hero_images.imageList} />

      <div className={styles.image} alt="large house">
        <div className={styles.overlay} />
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{startpage.hero.title}</h1>
          <p className={styles.description}>{startpage.hero.description}</p>
          <div className={styles.buttonContainer}>
            <Link to="/om-foretaget" title={`Läs mer om företaget`}>
              <Button className={styles.button} variant="secondary-ghosted">
                {startpage.hero.secondaryButton}
              </Button>
            </Link>
          </div>
        </div>

        <div className={styles.nav} onClick={handleClick}>
          <div className={styles.arrowWrapper} />

          <FaIcon icon="arrow-down" prefix="far" className={styles.icon} />
        </div>
      </div>
    </section>
  )
}

export default Hero
