import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import BackgroundSlideshow from "./BackgroundSlideshow"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"

const Hero = props => {
  const { ribbelement } = useContext(GlobalContext)

  const pageImages = normalizeUrlsFromImageList(
    ribbelement.page_images.imageList
  )

  return (
    <section className={styles.container}>
      <BackgroundSlideshow images={ribbelement.slider_images.imageList} />

      <div className={styles.image} alt="large house">
        <div className={styles.overlay} />
        <div className={styles.textContainer}>
          <img
            src={`${pageImages.ribblogo.url}?q=70`}
            className={styles.logo}
            alt="huskomponenter logotyp"
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
