import React from "react"
import styles from "./styles.module.scss"
import cx from "classnames"

const Milestone = props => {
  const style = { "--item-color": props.color }

  const imageUrl = props.image && props.image.fields.file.url

  return (
    <div
      className={cx(styles.container, {
        [styles.firstMilestone]: props.index === 0,
        [styles.lastMilestone]: props.index === props.timelineLength - 1,
      })}
    >
      <p className={styles.title}>{props.title}</p>
      <div className={styles.outerCircle} style={style}>
        <div className={styles.innerCircle}>
          <div className={styles.centerCircle} />
        </div>
      </div>
      <p className={styles.year}>{props.year}</p>
      <div className={cx(styles.popup, props.className)}>
        <img
          src={`https:${imageUrl}?q=70`}
          alt=""
          className={styles.popupImage}
        />
        {imageUrl && <div className={styles.divider} />}
        <p className={styles.popupText}>{props.description}</p>
      </div>
    </div>
  )
}

export default Milestone
