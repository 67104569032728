import React from "react"
import MainLayout from "layout/MainLayout"
import Hero from "./Hero"
import ContactConsumer from "components/ContactConsumer"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"
import Presentation from "./Presentation"

const Home = () => {
  ReactGA.pageview("/")

  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - Sunt förnuft från Dalarna</title>

        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus - Sunt förnuft från Dalarna"
        />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="twitter:description"
          content="Dalahus med rötter i Linghed har kompetens och resurser att tillverka och leverera enskilda komponenter till många typer av byggprojekt, t.ex. takstolar, takkassetter, väggblock, fönster och annat byggmaterial"
        />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial,KL-trä,CLT"
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Dalahus med rötter i Linghed har kompetens och resurser att tillverka och leverera enskilda komponenter till många typer av byggprojekt, t.ex. takstolar, takkassetter, väggblock, fönster och annat byggmaterial"
        />

        <meta
          property="og:title"
          content="Dalahus - Huskomponenter för företag | Unika byggen, inga kopior"
        />

        <meta
          property="og:description"
          content="Dalahus med rötter i Linghed har kompetens och resurser att tillverka och leverera enskilda komponenter till många typer av byggprojekt, t.ex. takstolar, takkassetter, väggblock, fönster och annat byggmaterial"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/" />
      </Helmet>
      <Hero />
      <Presentation />
      <ContactConsumer />
    </MainLayout>
  )
}

export default Home
