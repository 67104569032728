import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"
import List from "./List"

const Environment = props => {
  const { aboutcompany } = useContext(GlobalContext)
  const images = normalizeUrlsFromImageList(aboutcompany.images.imageList)

  return (
    <div className={styles.container}>
      <div
        style={{ backgroundImage: `url(${images.environment_bg.url}?q=70)` }}
        className={styles.image}
      />
      <div className={styles.list}>
        <h2 className={styles.title}>{aboutcompany.environment.title}</h2>
        <p className={styles.description}>
          {aboutcompany.environment.description}
        </p>
        <List items={aboutcompany.environment_list.texts} />
      </div>
    </div>
  )
}

export default Environment
