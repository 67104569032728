import { library } from "@fortawesome/fontawesome-svg-core"

import { faChevronDoubleDown } from "@fortawesome/pro-regular-svg-icons"
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons"

import {
  faEnvelope,
  faPhone,
  faArrowRight,
  faArrowLeft,
  faComments,
  faHome,
  faFileAlt,
  faHandshake,
  faBars,
  faTimes,
  faUser,
  faChevronDown,
  faExpand,
} from "@fortawesome/pro-light-svg-icons"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

library.add(
  faChevronDoubleDown,
  faEnvelope,
  faPhone,
  faFacebook,
  faInstagram,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faComments,
  faHome,
  faFileAlt,
  faHandshake,
  faBars,
  faTimes,
  faUser,
  faChevronDown,
  faExpand
)
