import React from "react"
import styles from "./styles.module.scss"

const DescriptionSection = props => {
  const { project } = props

  return (
    <section className={styles.descriptionSection}>
      {project.description && (
        <React.Fragment>
          <h2 className={styles.title}>Kort om objektet</h2>
          <p>{project.description}</p>
        </React.Fragment>
      )}
      {!project.description && <h2 className={styles.title}>Bilder </h2>}
    </section>
  )
}

export default DescriptionSection
