import React from "react"
import styles from "./styles.module.scss"

const MapSection = props => {
  return (
    <section className={styles.mapContainer}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1318.9567291232768!2d15.880392256967822!3d60.78221077942154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x466712de04766d7b%3A0x8d3112fce1299940!2sHuskomponenter+Linghed+AB!5e0!3m2!1ssv!2sse!4v1554294366243!5m2!1ssv!2sse"
        title="Dalahus Location"
        width="100%"
        height="100%"
        allowFullScreen
        frameBorder="0"
      />
    </section>
  )
}

export default MapSection
