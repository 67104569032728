import React, { useContext } from "react"
import MainLayout from "layout/MainLayout"
import { GlobalContext } from "globalContext"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"
import styles from "./styles.module.scss"
import Employee from "./Employee"
import Hero from "./Hero"

const Ribbelement = props => {
  const { ribbelement } = useContext(GlobalContext)

  ReactGA.pageview("/ribbelement")

  const filterContacts = values => {
    if (values)
      return Object.values(values).filter(value => value.key === "contact-2")
  }

  if (!ribbelement) return null

  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - Ribbelement | Tillverkar KL-Trä (CLT) i Falun</title>

        <meta name="twitter:card" content="summary" />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="twitter:title"
          content="Dalahus - Ribbelement | Tillverkar KL-Trä (CLT) i Falun"
        />

        <meta
          name="keywords"
          content="Dalahus,Massivpress,Ribbelement,KL-Trä,CLT,Falun"
        />

        <meta
          name="twitter:description"
          content="Dalahus delägda dotterbolag Massiv Press med varumärke Ribbelement tillverkar KL-Trä, eller Korslimmat trä som förkortningen kommer ifrån."
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Dalahus delägda dotterbolag Massiv Press med varumärke Ribbelement tillverkar KL-Trä, eller Korslimmat trä som förkortningen kommer ifrån."
        />

        <meta
          property="og:title"
          content="Dalahus - Ribbelement | Tillverkar KL-Trä (CLT) i Falun"
        />

        <meta
          property="og:description"
          content="Dalahus delägda dotterbolag Massiv Press med varumärke Ribbelement tillverkar KL-Trä, eller Korslimmat trä som förkortningen kommer ifrån."
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/ribbelement" />
      </Helmet>

      <Hero />

      <div className={styles.container}>
        <p className={styles.text}>{ribbelement.hero.description}</p>
      </div>

      <iframe
        className={styles.responsiveiframe}
        src="https://www.youtube.com/embed/SCXFLUpndFE"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="Ribbelement"
      ></iframe>

      <div className={styles.contactSection}>
        {filterContacts(ribbelement) &&
          filterContacts(ribbelement).map(employee => (
            <Employee employee={employee} />
          ))}
      </div>
    </MainLayout>
  )
}

export default Ribbelement
