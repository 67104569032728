import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { images } from "images"

const Hero = props => {
  const { aboutcompany } = useContext(GlobalContext)

  return (
    <div className={styles.container}>
      <div
        style={{ backgroundImage: `url(${images.aboutCompany.bg_hero}?=70)` }}
        className={styles.imageContainer}
      >
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{aboutcompany.hero.title}</h1>
          <p className={styles.description}>{aboutcompany.hero.description}</p>
        </div>
        <div className={styles.overlay} />
      </div>
    </div>
  )
}

export default Hero
