import React from "react"
import styles from "./styles.module.scss"

const List = props => {
  return (
    <ul className={styles.list}>
      {props.items.map(item => (
        <li key={item} className={styles.listItem}>
          {item}
        </li>
      ))}
    </ul>
  )
}

export default List
