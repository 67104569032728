export default {
  container: { background: "rgba(255, 255, 255, 0.95)" },
  image: { boxShadow: "0 0 10px rgba(0,0,0,0.3)" },
  arrow: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    fill: "#4373b9",
    opacity: 0.6,
    transition: "opacity 200ms",

    ":hover": {
      opacity: 1,
    },
  },
  close: {
    fill: "#1f1f1f",
    opacity: 0.6,
    transition: "all 200ms",
    width: "40px",
    height: "40px",

    ":hover": {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    borderRadius: 40,
    height: 40,
    marginTop: -20,

    "@media (min-width: 768px)": {
      height: 70,
      padding: 15,
    },
  },
  footer: {
    color: "#1f1f1f",
    fontFamily: "Arial",
  },
  thumbnail: {
    boxShadow: "0 0 0 2px rgba(0,0,0,0.5)",
    margin: "0 10px",
  },
  thumbnail__active: {
    boxShadow: "0 0 0 2px #4373b9",
  },
}
