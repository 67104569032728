const trimSpaces = words => {
  return words.replace(/\s/g, "")
}

export const normalizeUrlsFromImageList = imagelist => {
  if (imagelist !== undefined)
    return imagelist
      .map(image => image.fields)
      .reduce(
        (acc, image) => ({
          ...acc,
          [trimSpaces(image.title).toLowerCase()]: {
            url: `https:${image.file.url}`,
            title: image.title,
          },
        }),
        {}
      )
}

export const normalizeImageToLightbox = images => {
  return images
    .map(image => image.fields)
    .reduce(
      (acc, image) => [
        ...acc,
        {
          src: `https:${image.file.url}`,
          caption: image.title,
        },
      ],
      []
    )
}
