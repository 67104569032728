import React from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"

const ContactRow = props => {
  return (
    <li className={styles.listItem}>
      <FaIcon icon={props.icon} prefix={props.prefix} />
      {props.variant === "brand" ? (
        <a
          href={props.value}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.brand}
          title={`Besök vår ${props.icon}`}
        >
          {props.icon}
        </a>
      ) : (
        <a
          href={
            props.icon === "phone"
              ? `tel:+46${props.value.replace(/^0+/, "")}`
              : `mailto:${props.value}`
          }
          className={styles.contactLink}
          title={`Kontakta oss`}
        >
          {props.value}
        </a>
      )}
    </li>
  )
}

export default ContactRow
