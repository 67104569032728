import React, { useContext } from "react"
import styles from "./styles.module.scss"
import Step from "../Step"
import { GlobalContext } from "globalContext"

const Steps = props => {
  const { steps } = useContext(GlobalContext)

  return (
    <div className={styles.container}>
      {Object.values(steps).map(step => (
        <Step
          key={step.title}
          icon={step.iconName}
          title={step.title}
          description={step.description}
        />
      ))}
    </div>
  )
}

export default Steps
