import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./styles.module.scss"
import cx from "classnames"

class FaIcon extends React.PureComponent {
  static defaultProps = {
    prefix: "far",
  }

  render() {
    const { color, icon, prefix, className, size } = this.props

    return (
      <div
        onClick={this.props.onClick}
        className={cx(styles.container, className)}
      >
        <FontAwesomeIcon icon={[prefix, icon]} size={size} color={color} />
      </div>
    )
  }
}

export default FaIcon
