import React, { useState } from "react"
import styles from "./styles.module.scss"
import FaIcon from "components/FaIcon"
import cx from "classnames"
import NavLink from "components/Header/Navbar/NavLink"
import { filterLinks } from "utils/filterLinks"

const BurgerMenu = props => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => setIsOpen(!isOpen)
  const closeMenu = () => setIsOpen(false)

  const navLinks = filterLinks(props.links, "navbar")
  const dropdownLinks = filterLinks(props.links, "dropdown")

  const links = navLinks.concat(dropdownLinks)

  return (
    <div className={styles.menu}>
      <div
        onClick={toggleMenu}
        className={cx(styles.container, props.className, {
          [styles.open]: isOpen,
        })}
      >
        <FaIcon
          icon={isOpen ? "times" : "bars"}
          className={styles.icon}
          prefix="fal"
        />
      </div>
      <div
        onClick={closeMenu}
        className={cx(styles.whiteout, { [styles.open]: isOpen })}
      >
        <div className={styles.menuOpen}>
          <ul className={styles.menuList}>
            {links.map(link => (
              <li key={link.value} className={styles.listItem}>
                <NavLink
                  className={styles.link}
                  to={link.url}
                  value={link.value}
                >
                  {link.value}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
