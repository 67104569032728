import React, { useContext } from "react"
import MainLayout from "layout/MainLayout"
import DownloadPDF from "./DownloadPDF"
import ProductSection from "./ProductSection"
import { GlobalContext } from "globalContext"
import ContactConsumer from "components/ContactConsumer"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"

const Products = props => {
  const { products } = useContext(GlobalContext)

  ReactGA.pageview("/produkter")

  const filterProducts = values => {
    if (values)
      return Object.values(values).filter(
        value => value.key !== "leverans_deklarationer"
      )
  }

  if (!products) return null

  return (
    <MainLayout>
      <Helmet>
        <title>
          Dalahus - Produkter | Takstolar och annat byggmaterial i Linghed,
          Falun
        </title>

        <meta name="twitter:card" content="summary" />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="twitter:title"
          content="Dalahus - Produkter | Takstolar och annat byggmaterial i Linghed, Falun"
        />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta
          name="twitter:description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          property="og:title"
          content="Dalahus - Produkter | Takstolar och annat byggmaterial i Linghed, Falun"
        />

        <meta
          property="og:description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/produkter" />
      </Helmet>
      <DownloadPDF />
      {filterProducts(products) &&
        filterProducts(products).map(product => (
          <ProductSection
            description={product.body}
            title={product.title}
            images={product.imageList}
            key={product.title}
          />
        ))}
      <ContactConsumer />
    </MainLayout>
  )
}

export default Products
