import React from "react"
import EmployeeCard from "components/EmployeeCard"
import styles from "./styles.module.scss"

const BranchContainer = props => {
  const { title, employees } = props

  const branchEmployees = Object.values(employees).filter(
    employee => employee.branch === title
  )

  return (
    <section className={styles.container}>
      <h3 className={styles.title}>{title}</h3>

      <section className={styles.branchContainer}>
        <section className={styles.cardsContainer}>
          {branchEmployees.map(employee => (
            <EmployeeCard key={employee.key} employee={employee} />
          ))}
        </section>
      </section>
    </section>
  )
}

export default BranchContainer
