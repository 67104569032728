import React, { useContext } from "react"
import { GlobalContext } from "globalContext"
import BranchContainer from "./BranchContainer"
import styles from "./styles.module.scss"

const EmployeesSection = () => {
  const { employees } = useContext(GlobalContext)
  const branches = [
    ...new Set(Object.values(employees).map(employee => employee.branch)),
  ]

  return (
    <section className={styles.employeesSection}>
      {branches.map(branch => (
        <BranchContainer key={branch} title={branch} employees={employees} />
      ))}
    </section>
  )
}

export default EmployeesSection
