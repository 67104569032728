import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"
import { GlobalContext } from "globalContext"

const PageWideImage = props => {
  const { aboutcompany } = useContext(GlobalContext)

  const pageImages = normalizeUrlsFromImageList(aboutcompany.images.imageList)

  return (
    <div
      style={{ backgroundImage: `url(${pageImages.dalahuslager.url}?q=70)` }}
      className={styles.container}
    >
      <span className={styles.imageTitle}>{pageImages.dalahuslager.title}</span>
    </div>
  )
}

export default PageWideImage
