import React from "react"
import styles from "./styles.module.scss"
import ImageSlider from "components/ImageSlider"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const ProductSection = props => {
  return (
    <div className={styles.container}>
      {props.title === "Takstolar" ? (
        <h1 className={styles.title}>{props.title}</h1>
      ) : (
        <h2 className={styles.title}>{props.title}</h2>
      )}
      <div className={styles.imageSlider}>
        <ImageSlider images={props.images} />
      </div>
      <div
        className={styles.textContainer}
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(props.description),
        }}
      />
    </div>
  )
}

export default ProductSection
