import "./utils/polyfills.js"
import "react-app-polyfill/ie11"
import React from "react"
import Navigation from "./Navigation"
import "normalize.css"
import "./App.module.scss"
import "icons.js"
import "styles/globals.scss"
import "styles/transitions.scss"
import { GlobalContext } from "globalContext"
import { useApi } from "api"
import ReactGA from "react-ga"
import CookieConsent from "react-cookie-consent"

const App = ({ defaultData }) => {
  const { data } = useApi(defaultData)

  ReactGA.initialize("UA-138897152-1")
  ReactGA.pageview("/")

  return (
    <>
      <GlobalContext.Provider value={data}>
        <Navigation />
      </GlobalContext.Provider>
      <CookieConsent
        buttonText="Ok, jag förstår"
        style={{
          background: "#e4ebf5",
          color: "#000",
          padding: "16px",
          justifyContent: "center",
        }}
        contentStyle={{
          flex: "none",
          maxWidth: "90vw",
        }}
        buttonClasses="primary-ghosted cookiesButton"
        disableButtonStyles={true}
      >
        Vi använder oss av cookies för att förbättra din upplevelse på sidan
      </CookieConsent>
    </>
  )
}

export default App
