import React from "react"
import styles from "./styles.module.scss"
import Navbar from "./Navbar"
import Logo from "./Logo"
import BurgerMenu from "./BurgerMenu"

const Header = props => {
  return (
    <header className={styles.container}>
      <section className={styles.content}>
        <Logo />
        <Navbar className={styles.nav} {...props} />
      </section>
      <BurgerMenu className={styles.burgerMenu} {...props} />
    </header>
  )
}

export default React.memo(Header)
