import React, { useContext } from "react"
import Hero from "./Hero"
import EmployeesSection from "./EmployeesSection"
import MainLayout from "layout/MainLayout"
import { GlobalContext } from "globalContext"
import MapSection from "./MapSection"
import ContactSection from "components/ContactSection"
import HistorySection from "components/HistorySection"
import Section from "components/Section"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"

const Contact = () => {
  ReactGA.pageview("/kontakt")

  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - Kontakta oss | Unika byggen, inga kopior</title>
        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus - Kontakta oss | Unika byggen, inga kopior"
        />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="twitter:description"
          content="Kom i kontakt med oss, vi svarar mer än gärna på era frågor gällande att bygga hus med Dalahus"
        />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Kom i kontakt med oss, vi svarar mer än gärna på era frågor gällande att bygga hus med Dalahus"
        />

        <meta
          property="og:title"
          content="Dalahus - Kontakta oss | Unika byggen, inga kopior"
        />

        <meta
          property="og:description"
          content="Kom i kontakt med oss, vi svarar mer än gärna på era frågor gällande att bygga hus med Dalahus"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/kontakt" />
      </Helmet>
      <Hero />
      <EmployeesSection />
      <MapSection />
      <ContactConsumer />
    </MainLayout>
  )
}

const ContactConsumer = () => {
  const { startpage } = useContext(GlobalContext)

  return (
    <Section>
      <HistorySection texts={startpage} />
      <ContactSection texts={startpage} />
    </Section>
  )
}

export default Contact
