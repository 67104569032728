import React from "react"
import { Router, Redirect } from "@reach/router"
import FourOhFour from "pages/FourOhFour"

import LazyHome from "pages/Home"
import LazyJRDalaBygg from "pages/JRDalaBygg"
import LazyAboutCompany from "pages/AboutCompany"
import LazyGDPR from "pages/GDPR"
import LazyForPrivate from "pages/ForPrivate"
import Contact from "pages/Contact"
import ProjectsShow from "pages/ProjectsShow"
import BuildProcess from "pages/BuildProcess"
import Products from "pages/Products"
import Ribbelement from "pages/Ribbelement"
import Work from "pages/Work"

const Navigation = () => (
  <>
    <Router>
      <LazyHome path="/" />
      <Redirect from="inspiration" to="/" noThrow />
      <Redirect from="huskomponenter" to="/" noThrow />
      <LazyJRDalaBygg path="jr-dala-bygg" />
      <LazyAboutCompany path="om-foretaget" />
      <LazyGDPR path="gdpr" />
      <LazyForPrivate path="privat" />
      <Contact path="kontakt" />
      <Work path="jobb" />
      <BuildProcess path="byggprocessen" />
      <ProjectsShow path="objekt/:id" />
      <Products path="produkter" />
      <Ribbelement path="ribbelement" />
      <FourOhFour default />
    </Router>
  </>
)

export default Navigation
