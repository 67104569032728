import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const GDPRSection = () => {
  const { gdpr } = useContext(GlobalContext)

  const image = normalizeUrlsFromImageList(gdpr.images.imageList)

  return (
    <section className={styles.container}>
      <div className={styles.gdpr}>
        <h2 className={styles.title}>{gdpr.title.value}</h2>
        <div
          className={styles.gdprRichText}
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(gdpr.gdpr.body),
          }}
        />

        <p className={styles.text}>
          För mer information kontakta:
          <a
            href={`mailto:${gdpr.contact_mail.value}`}
            className={styles.email}
            title={`Maila oss`}
          >
            {gdpr.contact_mail.value}
          </a>
        </p>
      </div>

      <div className={styles.imageContainer}>
        <img
          src={`${image.gdpr.url}?q=70`}
          className={styles.image}
          alt="woman holding EU flag"
        />
      </div>
    </section>
  )
}

export default GDPRSection
