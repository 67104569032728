import React, { useContext } from "react"
import Phase from "./Phase"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"

const Process = () => {
  const { buildprocess } = useContext(GlobalContext)

  const phases = Object.values(buildprocess).filter(phase => {
    return phase.key.includes("fas")
  })

  return (
    <section className={styles.container}>
      {phases.map(phase => {
        return (
          <Phase
            key={phase.key}
            title={phase.title}
            tabs={phase.tabs}
            imageUrl={phase.image && phase.image.fields.file.url}
          />
        )
      })}
    </section>
  )
}

export default Process
