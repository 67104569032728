import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"

const SellingPoints = () => {
  const { newstartpage } = useContext(GlobalContext)

  return (
    <section className={styles.container}>
      <b className={styles.title}>{newstartpage.selling_points_title.value}</b>

      <ul className={styles.sellingPointsList}>
        {newstartpage.selling_points.texts.map(sellingPoint => (
          <li className={styles.sellingPoint} key={sellingPoint}>
            {sellingPoint}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default SellingPoints
