import React from "react"
import styles from "./styles.module.scss"
import cx from "classnames"
import FaIcon from "components/FaIcon"

const Image = props => {
  const { onClick, image, status, index } = props

  const handleClick = () => onClick(index)

  return (
    <div onClick={handleClick} className={cx(styles.container, styles[status])}>
      <div
        style={{ backgroundImage: `url(https:${image.fields.file.url}?q=70)` }}
        className={styles.image}
      >
        <div className={styles.overlay} />
        <div className={styles.imageTextContainer}>
          <span className={styles.imageText}>{image.fields.title}</span>
          {image.fields.description && (
            <p className={styles.imageDescription}>
              {image.fields.description}
            </p>
          )}
        </div>

        {status === "currentImage" && (
          <div className={styles.fullscreenIcon}>
            <FaIcon icon="expand" prefix="fal" color="white" size="2x" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Image
