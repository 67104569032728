import React from "react"
import styles from "./styles.module.scss"
import Button from "components/Button"
import FaIcon from "components/FaIcon"

const ContactSection = props => {
  const { texts } = props

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{`${texts.contact.title}`}</h2>
      <p className={styles.description}>{texts.contact.description}</p>
      <div className={styles.buttonContainer}>
        <a
          href={`tel:+46${texts.contact_phone.value.replace(/^0+/, "")}`}
          title={`Ring oss`}
        >
          <Button variant="primary" className={styles.button}>
            <FaIcon icon="phone" prefix="fal" className={styles.icon} />
            <span className={styles.buttonText}>
              {texts.contact.primaryButton}
            </span>
          </Button>
        </a>
      </div>
    </section>
  )
}

export default ContactSection
