import React from "react"
import styles from "./styles.module.scss"
import { Helmet } from "react-helmet"
import MainLayout from "layout/MainLayout"
import ReactGA from "react-ga"

const FourOhFour = props => {
  ReactGA.pageview("/404")

  return (
    <MainLayout>
      <Helmet>
        <title>
          Dalahus - 404 Sidan kunde inte hittas | Unika byggen, inga kopior
        </title>

        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus - 404 Sidan kunde inte hittas | Unika byggen, inga kopior"
        />

        <meta
          name="twitter:description"
          content="Denna sida finns tyvärr inte längre"
        />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Byggkomponenter,Byggmaterial"
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Denna sida finns tyvärr inte längre"
        />

        <meta
          property="og:title"
          content="Dalahus - Sidan kunde inte hittas | Unika byggen, inga kopior"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/404" />
      </Helmet>
      <div className={styles.container}>
        <h1 className={styles.title}>404</h1>
        <p className={styles.text}>
          Tyvärr verkar det som att du har hamnat på en sida som inte längre
          finns.
        </p>
      </div>
    </MainLayout>
  )
}

export default FourOhFour
