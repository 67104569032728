import React from "react"
import styles from "./styles.module.scss"
import Section from "./Section"
import ContactRow from "./ContactRow"
import FooterLink from "./FooterLink"
import cx from "classnames"
import CopyrightFooter from "components/CopyrightFooter"
import { Link } from "@reach/router"
import Logo from "components/Header/Logo"
import { filterLinks } from "utils/filterLinks"

const Footer = ({ texts, links }) => {
  const footerLinks = filterLinks(links, "footer")

  return (
    <footer className={styles.container}>
      <div className={styles.sections}>
        <Section className={styles.logoSection}>
          <Logo className={styles.logo} />
        </Section>
        <Section title={texts.company_title.value}>
          <address
            className={cx(styles.text, styles.address)}
            itemScope
            itemType="http://schema.org/PostalAddress"
          >
            <p>
              <span itemProp="name">{texts.company_name.value}, </span>
              <span itemProp="addressLocality">
                {texts.company_address.value},{" "}
              </span>
              <span itemProp="postalCode">{texts.company_zip.value} </span>
              <span itemProp="addressRegion">{texts.company_city.value}</span>
            </p>
          </address>
          <p className={styles.text}>{texts.legal.value}</p>
          <Link
            to="/gdpr"
            className={styles.gdpr}
            title={`Läs om vår GDPR policy`}
          >
            Hantering av personuppgifter (GDPR)
          </Link>
        </Section>
        <Section title={texts.contact_title.value}>
          <ul className={styles.contactList}>
            <ContactRow
              value={texts.email.value}
              icon="envelope"
              prefix="fal"
            />
            <ContactRow value={texts.phone.value} icon="phone" prefix="fal" />
            <ContactRow
              variant="brand"
              value={texts.facebook.value}
              icon="facebook"
              prefix="fab"
            />
            <ContactRow
              variant="brand"
              value={texts.instagram.value}
              icon="instagram"
              prefix="fab"
            />
          </ul>
        </Section>
        <Section title={texts.navigation_title.value}>
          <ul className={styles.navigation}>
            {footerLinks.map(link => (
              <FooterLink key={link.value} to={link.url}>
                {link.value}
              </FooterLink>
            ))}
          </ul>
        </Section>
      </div>
      <CopyrightFooter text={texts.copyright.value} />
    </footer>
  )
}

export default Footer
