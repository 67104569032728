import React from "react"
import { Link } from "@reach/router"
import styles from "./styles.module.scss"

const FooterLink = props => {
  return (
    <li className={styles.listItem}>
      <Link
        className={styles.navlink}
        to={props.to}
        title={`Gå till ${props.children}`}
      >
        <span className={styles.text}>{props.children}</span>
      </Link>
    </li>
  )
}

export default FooterLink
