import React from "react"
import MainLayout from "layout/MainLayout"
import Hero from "./Hero"
import Process from "./Process"
import { Helmet } from "react-helmet"
import ReactGA from "react-ga"

const BuildProcess = () => {
  ReactGA.pageview("/byggprocessen")

  return (
    <MainLayout>
      <Helmet>
        <title>Dalahus - Byggprocessen | Unika byggen, inga kopior</title>
        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:title"
          content="Dalahus - Byggprocessen | Unika byggen, inga kopior"
        />

        <meta http-equiv="Expires" content="1" />

        <meta
          name="twitter:description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          name="keywords"
          content="Takstolar,Falun,Linghed,Bygga,Hus,Limträ,Unika,Byggen,Huskomponenter,Byggmaterial"
        />

        <meta
          name="twitter:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:type" content="website" />

        <meta property="og:site_name" content="Dalahus" />

        <meta
          name="description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          property="og:title"
          content="Dalahus - Byggprocessen | Unika byggen, inga kopior"
        />

        <meta
          property="og:description"
          content="Dalahus med rötter i Linghed, Falun bygger och tillverkar unika hus och delar till alla typer av byggnader. Vi arbetar med trä av hög kvalitet och skapar byggnader med lång livstid. Vi levererar även takstolar, fönster och annat byggmaterial"
        />

        <meta
          property="og:image"
          content="https://s3.eu-central-1.amazonaws.com/dalahus-public/dalahus-thumb.png"
        />

        <meta property="og:url" content="https://dalahus.se/byggprocessen" />
      </Helmet>
      <Hero />
      <Process />
    </MainLayout>
  )
}

export default BuildProcess
