import React, { useContext } from "react"
import styles from "./styles.module.scss"
import ProjectCard from "components/ProjectCard"
import { GlobalContext } from "globalContext"

const FeaturedProjects = props => {
  const { projects } = useContext(GlobalContext)

  const featuredProjects = Object.values(projects).filter(
    project => project.featured === true
  )

  return (
    <div className={styles.container}>
      {Object.values(featuredProjects.slice(0, 3)).map(project => (
        <ProjectCard
          key={project.key}
          urlKey={project.key}
          image={project.primaryImage.fields.file.url}
          year={project.build_year}
          name={project.name}
        />
      ))}
    </div>
  )
}

export default FeaturedProjects
