import React, { useState, useEffect } from "react"
import styles from "./styles.module.scss"
import cx from "classnames"

const BackgroundSlideshow = props => {
  const { images } = props
  const [currentBackgroundIndex, setCurrentBackground] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentBackground((currentBackgroundIndex + 1) % images.length)
    }, 5000)
    return () => clearTimeout(timer)
  })

  return (
    <React.Fragment>
      {images.map((image, index) => (
        <div
          key={image.sys.id}
          className={cx(styles.image, {
            [styles.currentImage]: index === currentBackgroundIndex,
          })}
          style={{
            backgroundImage: `url(https:${image.fields.file.url}?q=70)`,
          }}
        />
      ))}
    </React.Fragment>
  )
}

export default BackgroundSlideshow
