import React, { useContext } from "react"
import styles from "./styles.module.scss"
import { GlobalContext } from "globalContext"
import { normalizeUrlsFromImageList } from "utils/normalizeUrl"

const Hero = props => {
  const { jrdalabygg } = useContext(GlobalContext)

  const images = normalizeUrlsFromImageList(jrdalabygg.images.imageList)

  return (
    <div className={styles.container}>
      <div
        style={{ backgroundImage: `url(${images.jrdalabygghero.url}?q=70)` }}
        className={styles.imageContainer}
      >
        <div
          style={{ backgroundImage: `url(${images.jrdalabygglogo.url}?q=70)` }}
          className={styles.logo}
        />
        <div className={styles.overlay} />
      </div>
    </div>
  )
}

export default Hero
